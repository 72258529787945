import React, { useState, useEffect, useRef } from "react";
import { useParams, withRouter } from "react-router";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { io } from "socket.io-client";
import { sha256 } from "js-sha256";
import Cookies from "universal-cookie";
import FileViewer from "react-file-viewer";

import { CONFIGS, TABS } from "../../constants/constants";
import "./chat.scss";
import apicalls from "./apiCalls";
import GroupMembers from "./GroupMembers";
import UploadImage from "./UploadImage";
import UploadDoc from "./UploadDoc";
import PreviewImage from "./PreviewImage";

import settings from "./img/settings.svg";
import clock from "./img/clock.png";
import tick from "./img/tick.png";
import bellIcon from "./img/bellIcon.gif";
import sendIcon from "./img/send-icon.svg";
import attachmentIcon from "./img/attachment-icon.svg";
import cameraIcon from "./img/camera-icon.svg";
import { utils } from "../../utility/utils";
import blank_profile_pic from "./img/blank_profile.png";
import pdf from "./img/pdf.svg";
import download from "./img/down-arrow.svg";
import doc from "./img/doc.svg";
import ppt from "./img/ppt.svg";
import txt from "./img/txt.svg";
import xls from "./img/xls.svg";
import alertIcon from "./img/alert.svg";
import { util } from "prettier";
import CourseHeader from "../headers/CourseHeader";
import SidebarContainer from "../sidebars/SidebarContainer";
import { dispatchIndividualBrand } from "../../actions/brandAction";
import { getProfileInfoOnly } from "../../actions/profileAction";
import { setAttendance, setSelectedCourse } from "../../actions/courseAction";
import { connect } from "react-redux";
import { setCurrentTab } from "../../actions/utilAction";
import { atCrs } from "../../actions/batchAction";

let cookies = new Cookies();
const aidInCookies = utils.getAid();
const profileData = cookies.get("Profile");

//GLOBAL VARIABLES
const SOCKET_CONNECT = "connect";
const SOCKET_DISCONNECT = "disconnect";
const INITIALIZE_WEB = "init_w"; //{ aid : "", version : "" }
const GROUP_MESSAGE_WEB = "g_m_w"; //{ mid: "", body: "", type: "", pid: "", pnam: "", GID: "", tim: "" }
// const blank_profile_pic = "./img/blank_profile.png";
const PROFILE_PIC_BASE = "https://eckppic.s3.amazonaws.com/";
const message_type = {
  text: 1,
  image: 2,
  video: 3,
  audio: 4,
  notification: 5,
  like: 6,
  seen: 7,
};
const USER_TYPE = {
  ADMIN: 1,
  STUDENT: 2,
  BANNED: 3,
};

// Connecting Socket
// Connecting Socket

const URL = CONFIGS.SOCKET_ENDPOINT;
let socket = io(URL + "?aid=" + utils.getAid() + "&tokn=" + cookies.get("at"), {
  transports: ["websocket"],
  upgrade: true,
  reconnectionAttempts: 30,
  reconnectionDelay: 3000,
});

function App(props) {
  console.log("props in socket :: ", props);
  const [stopAnotherApiCall, setStopAnotherApiCall] = useState(false);
  const divRef = useRef(null);
  let { gid: GID, cid, pid } = useParams();

  const [fileName, setFileName] = useState("");

  // const [renderCount, setRenderCount] = useState(0)
  const [ifScrolledUp, setIfScrolledUp] = useState(false);
  const [lockScroll, setLockScroll] = useState(true);
  const [PID, setPID] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [ifChanged, setIfChanged] = useState(false);
  const [toggleShowSettings, setToggleShowSettings] = useState(false);
  const [toggleUploadImage, setToggleUploadImage] = useState(false);
  const [toggleUploadDoc, setToggleUploadDoc] = useState(false);
  const [togglePreviewImages, setTogglePreviewImages] = useState(false);

  const [ifPreviouslyConnected, setIfPreviouslyConnected] = useState(
    socket?.connected || false
  );
  const [isConnected, setIsConnected] = useState(socket?.connected || false);
  const [currentGMPage, setCurrentGMPage] = useState(0);

  const [imagesState, setImagesState] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(-1);

  const [ifMessageSent, setIfMessageSent] = useState({
    status: false,
    mid: "",
  });
  const [ifCommEnabled, setIfCommEnabled] = useState(true);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedDoc, setUploadedDoc] = useState(null);

  const [currentUserProfileDetails, setCurrentUserProfileDetails] = useState(
    {}
  );
  const [currentGroup, setCurrentGroup] = useState({});
  const [currentGroupMember, setCurrentGroupMember] = useState({});
  const [allMessages, setAllMessages] = useState([]);
  const [newMessagesForUser, setNewMessagesForUser] = useState([]);
  const [allGroupMembers, setAllGroupMembers] = useState({});

  const [newMessage, setNewMessage] = useState("");

  const [uploadStatus, setUploadStatus] = useState(false);
  const [removeNewMessage, setRemoveNewMessage] = useState(false);

  const [brand, setBrand] = useState();
  const [selfPaced, setSelfPaced] = useState();
  const [joining, setJoining] = useState();
  const [uid, setUid] = useState();
  const [courseName, setCourseName] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [impartusEnabled, setImpartusEnabled] = useState();
  const [batchName, setBatchName] = useState();
  const [courseLocation, setCourseLocation] = useState();
  const [dp, setDp] = useState();
  const [userName, setUserName] = useState();
  const [primaryEmail, setPrimaryEmail] = useState();

  const [oppShared, setOppShared] = useState(0);
  const [showWeightage, setShowWeightage] = useState(false);
  const [showPassMarks, setShowPassMarks] = useState(false);
  const [showPlcStatus, setShowPlcStatus] = useState(false);
  const [placementEnable, setPlacementEnable] = useState(false);
  const [gradebookType, setGradebookType] = useState(1);
  const [breadCrumbsTemp, setBreadCrumbsTemp] = useState([]);
  const [showPlcPassStatus, setShowPlcPassStatus] = useState(
    "Congratulations! You are eligible for Placement Criteria"
  );
  const [showPlcFailStatus, setShowPlcFailStatus] = useState(
    "You are not eligible for the Placement.  "
  );
  const [plcPassStatusText, setPlcPassStatusText] = useState("PASS");
  const [plcFailStatusText, setPlcFailStatusText] = useState("FAIL");
  const [showContentStatus, setShowContentStatus] = useState(false);

  // useEffect(() => {

  // }, []);

  const authenticatCourse = async () => {
    if (!cookies.get("isLoggedIn")) {
      return window.open(CONFIGS.WEB_DASHBOARD_BASE_URL, "_self");
    }
    console.log("Calling atCrs!");
    atCrs({ pid, gid: GID, cid, dispatchIndividualBrand })
      .then(function (res) {
        console.log("atCrs res :: ", res);
        var expirydate = new Date();
        expirydate.setDate(expirydate.getDate() + 180);
        cookies.set("crs_tkn", res.data.data.at, {
          expires: expirydate,
          domain: CONFIGS.DOMAIN,
          path: "/",
        });
        console.log({ dataData: res.data.data });
        if (res.data.data.joined) {
          setJoining(new Date(res.data.data.joined));
        }
        if (res.data.data.uid) {
          setUid(res.data.data.uid);
        }
        console.log("This is my cid course", res.data.data.course.name);
        if (res.data.data.course.name) {
          setCourseName(res.data.data.course.name);
          let cname = res.data.data.course.name;
          setBreadCrumbsTemp([{ cname, link: "/view/?cid=" + cid }]);
          console.log({ cname: res.data.data.name });
          console.log({ breadCrumbsTemp });
        }
        if (res.data.data && res.data.data.phoneno) {
          setPhoneNo(res.data.data.phoneno);
        }
        if (res.data.data.selfPaced) {
          setSelfPaced(res.data.data.selfPaced);
        }
        setImpartusEnabled(res.data.data.course.impartus_enabled);
        setShowPassMarks(res.data.data.course.show_pass_marks);
        setShowWeightage(res.data.data.course.show_weightage);
        if ("show_content_status" in res.data.data.course) {
          setShowContentStatus(res.data.data.course["show_content_status"]);
        }

        let passStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_pass_text
          : "Congratulations! You are eligible for Placement Criteria";
        let failStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_fail_text
          : "You are not eligible for the Placement.  ";
        let plcpassStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_pass_status_text
          : "PASS";
        let plcfailStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_fail_status_text
          : "FAIL";

        setPlcPassStatusText(plcpassStatus);
        setPlcFailStatusText(plcfailStatus);

        setShowPlcFailStatus(failStatus);
        setShowPlcPassStatus(passStatus);
        let grdtype = res.data.data.course.gradebook_type
          ? res.data.data.course.gradebook_type
          : 1;
        setGradebookType(grdtype);
        setPlacementEnable(res.data.data.course.plcmnt_enable);

        let showplc = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.gradebook_show_plcmnt_stts
          : false;
        setShowPlcStatus(showplc);
        setBatchName(res.data.data.course.btch_name);
        setCourseLocation(res.data.data.location);

        let courseFound = res.data.data.course;
        if (res.data.data.location) {
          courseFound.loc_name = res.data.data.location;
        }
        if (courseFound) {
          props.setSelectedCourse(courseFound);
        }
        props.setAttendance(res.data.data.showAttendance);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  function scrollToBottom() {
    // console.log("OKAY, SCROLLING TO THE BOTTOM!");
    setStopAnotherApiCall(true);
    divRef.current.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      setStopAnotherApiCall(false);
    }, 1000);
  }
  function refreshPage() {
    window.location.reload(false);
  }
  async function handleSocketMessageWithMedia(m) {
    // console.log("IN handleSocketMessageWithMedia");
    let signedUrl = "";
    try {
      if (
        PID !== (m.pid || m.from) &&
        ((m.type === 1 && m.fob) || m.type === 2 || m.type === 4)
      ) {
        console.log("handleSocketMessageWithMedia in if pid check: ", m.body);
        signedUrl = await apicalls.chatMedia(PID, GID, m.body);
        // if (m.type === 2) {
        //   const uniqueImages = new Set([...imagesState, m]);
        //   // Convert back to array
        //   const uniqueImagesArray = Array.from(uniqueImages);
        //   console.log(uniqueImagesArray, "images in socket image message");
        //   // Set the state with unique images
        //   setImagesState(uniqueImagesArray);
        // }
        // console.log("handleSocketMessageWithMedia signedUrl: ", signedUrl);
      }
      // console.log("after handleSocketMessageWithMedia signedUrl: ", signedUrl);
      onSocketMessage(m, signedUrl); // Call the synchronous event handler with the result
    } catch (error) {
      console.error("Error handling socket message with media:", error);
      // Handle error appropriately, e.g., display an error message to the user
      onSocketMessage(m, signedUrl);
    }
  }
  useEffect(() => {
    console.log("ifScrolledUp!");
    if (ifScrolledUp === true) {
      console.log("PID in scroll and going to hit api:", PID);
      apicalls
        .getPreviousMessages(
          GID,
          currentGroupMember.lsmtim || "",
          allMessages,
          15,
          PID,
          false,
          "scroll"
        )
        .then(async (data) => {
          setStopAnotherApiCall(true);
          let { msgs, oldMsgs, newMsgs, images } = data;
          console.log({ getPreviousMessages: data });

          setAllMessages(oldMsgs);

          const uniqueImages = new Set([...imagesState, ...images]);
          // Convert back to array
          const uniqueImagesArray = Array.from(uniqueImages);
          console.log(uniqueImagesArray, "images in scroll");

          // Set the state with unique images
          setImagesState(uniqueImagesArray);
          setStopAnotherApiCall(false);
          setIfScrolledUp(false);
        })
        .catch((err) => {
          // // console.log({ getPreviousMessagesError: err });
        });
    }
  }, [ifScrolledUp]);

  useEffect(() => {
    let imageMessages = allMessages.filter((m) => m.type === 2);
    const uniqueImages = new Set(imageMessages);
    // Convert back to array
    const uniqueImagesArray = Array.from(uniqueImages);
    console.log(uniqueImagesArray, "images in useEffect of all messages");
    // Set the state with unique images
    setImagesState(uniqueImagesArray);
  }, [allMessages]);

  useEffect(() => {
    function recieveMissedMessages() {
      console.log("\nIN RECIEVE MISSED MESSAGES FUNC\n", allMessages.length);
      apicalls
        .getPreviousMessages(
          GID,
          (currentGroupMember && currentGroupMember.lsmtim) || "",
          allMessages,
          30,
          PID,
          true,
          "useEffect if not first render"
        )
        .then((data) => {
          let { msgs, oldMsgs, newMsgs, images } = data;
          console.log({ imagesInOffline: images }, "offlinemessages");
          console.log(
            "\n\n\nIn offline messages :: ",
            { oldMsgs, newMsgs, images },
            "\n\n\n"
          );
          setAllMessages(oldMsgs);
          setNewMessagesForUser(newMsgs);
          // setImagesState(images);
          // console.log("newmessages : ", newMsgs);
          setIsLoading(false);
          setStopAnotherApiCall(false);
          scrollToBottom();
          apicalls
            .updateLastSeen({ GID })
            .then((data) => {
              // // console.log({ updateLastSeen: data });
            })
            .catch((err) => {});
        });
    }
    console.log("ifPreviouslyConnected : ", ifPreviouslyConnected);
    // should not work on first render, since first render happens on internet connection only.
    if (isConnected && navigator.onLine && ifPreviouslyConnected) {
      recieveMissedMessages();
    }
  }, [isConnected]);

  useEffect(() => {
    console.log("\n\n\n\n\n\n In main use effect of Discussion! ", cid, " \n");
    setCurrentTab(TABS.DISCUSSION);
    (async () => {
      // if (!cid) {
      await authenticatCourse();
      // }
      await props.getProfileInfoOnly();
      setDp(props.ppic);
      setUserName(props.profileName);
      setPrimaryEmail(props.primaryEmail);
      // await getAttendanceDataForLearner(props?.selected_course?._id)
    })();

    setStopAnotherApiCall(true);
    let pid = utils.getPid();
    setPID(pid);

    // console.log("pid && GID:", pid !== "" && GID !== "");
    if (GID) {
      // console.log("Calling the apis now with pid: ", pid);
      apicalls
        .profileFind(pid)
        .then((data) => {
          // // console.log({ ProfileFind: data });
          setCurrentUserProfileDetails(data);
        })
        .catch((err) => {
          // // console.log({ ProfileFindError: err });
          setCurrentUserProfileDetails({});
        });
      apicalls
        .fetchMember({ GID, pid })
        .then((memberData) => {
          // // console.log({ fetchMembersInAPI: memberData });
          setCurrentGroupMember(memberData);
          apicalls
            .fetchGroup({ GID, pid })
            .then((data) => {
              // // console.log({ groupDetailsInAPI: data });
              setCurrentGroup(data);
              if (memberData.type === USER_TYPE["ADMIN"]) {
                setIfCommEnabled(true);
              } else if (memberData.type === USER_TYPE["BANNED"]) {
                setIfCommEnabled(false);
              } else setIfCommEnabled(data.gtyp);
            })
            .catch((err) => {
              // // console.log({ FetchGroupError: err });
              setCurrentGroup({});
            });
          // alert("Calling GetPreviousMessage in useEffect");
          apicalls
            .getPreviousMessages(
              GID,
              (memberData && memberData.lsmtim) || "",
              allMessages,
              30,
              pid,
              false,
              "useEffect"
            )
            .then((data) => {
              let { msgs, oldMsgs, newMsgs, images } = data;
              console.log({ imagesInUseEffect: images });
              setAllMessages(oldMsgs);
              setNewMessagesForUser(newMsgs);
              // setImagesState(images);
              // console.log("newmessages : ", newMsgs);
              scrollToBottom();
              apicalls
                .updateLastSeen({ GID, pid })
                .then((data) => {})
                .catch((err) => {});
            })
            .catch((err) => {
              // // console.log({ getPreviousMessagesError: err });
            });
        })
        .catch((err) => {
          setCurrentGroupMember({});
        });
      apicalls
        .getListv16({ GID, pid })
        .then((data) => {
          // // console.log({ getListv16: data });
          let { profileMap, members, totalMembers } = data;
          setAllGroupMembers({ profileMap, members, totalMembers });
        })
        .catch((err) => {});
    }

    console.log("GOINGTOCONNECTSOCKET: && ");
    socket.on(SOCKET_CONNECT, onSocketConnect);
    socket.on(SOCKET_DISCONNECT, onSocketDisconnect);
    socket.on(GROUP_MESSAGE_WEB, handleSocketMessageWithMedia);
    socket.on("reconnect", onSocketReconnect);
    socket.on("connect_failed", (err) => {
      console.log({ err });
      console.log(`connect_failed due to ${err.message}`);
    });
    socket.on("reconnect_error", (err) => {
      console.log({ err });
      console.log(`reconnect_error due to ${err.message}`);
    });
    socket.on("connect_error", (err) => {
      console.log({ err });
      console.log(`connect_error due to ${err.message}`);
    });

    setLockScroll(false);

    setIsLoading(false);
    setStopAnotherApiCall(false);

    return () => {
      // console.log("\n\n\n\n\n\n\n\n\n\n\n\nSigning off\n\n\n\n\n\n\n\n\n");
      socket.disconnect();
      socket.off(SOCKET_CONNECT, onSocketConnect);
      socket.off(SOCKET_DISCONNECT, onSocketDisconnect);
      socket.off(GROUP_MESSAGE_WEB, handleSocketMessageWithMedia);
    };
  }, []);
  // console.log({ currentGroupMember });
  useEffect(() => {
    // console.log("ifChanged is changed : ", { ifChanged });
    apicalls
      .getListv16({ GID })
      .then((data) => {
        // // console.log({ getListv16: data });
        let { profileMap, members, totalMembers } = data;
        setAllGroupMembers({ profileMap, members, totalMembers });
      })
      .catch((err) => {
        // // console.log({ getListv16Error: err });
      });
  }, [ifChanged]); // This makes sure that we get the updated data in the modal where we list group users.

  function onSocketReconnect(socket) {
    console.log("Socket reconnect : ", socket);
  }
  const eventHandlers = {
    toggleImagePreview: (e) => setTogglePreviewImages(!togglePreviewImages),
    openImage: (e, msg) => {
      console.log("In openImage ::: ", { msg, imagesState });
      setTogglePreviewImages(!togglePreviewImages);
      if (togglePreviewImages === false) {
        let index = imagesState.findIndex(
          (x) => (x.mid || x._id) === (msg.mid || msg._id)
        );
        console.log("In openImage finding index: ", index);
        setCurrentImageIndex(index);
      }
    },
    makeAdminHandler: async (member) => {
      // console.log("In MakeAdminHandler : ", member);
      setIsLoading(true);
      let mpid = member.pid;
      let textToAlert = "Are you sure you want to add an admin to this group?";
      if (window.confirm(textToAlert) == true) {
        let response = await apicalls.makeAdmin(PID, GID, mpid);
        setIfChanged(true);
        // Update the group members' data after the ban action
        await apicalls
          .getListv16({ GID })
          .then((data) => {
            let { profileMap, members, totalMembers } = data;
            setAllGroupMembers({ profileMap, members, totalMembers });
          })
          .catch((err) => {
            // console.log(err);
          });
      }
      setIsLoading(false);
      return true;
    },
    removeAdminHandler: async (member) => {
      // console.log("In removeAdminHandler : ", member);
      setIsLoading(true);
      let mpid = member.pid;
      let textToAlert = "Are you sure you want to remove the admin privileges?";
      if (window.confirm(textToAlert) == true) {
        let response = await apicalls.removeAdmin(PID, GID, mpid);
        setIfChanged(true);
        await apicalls
          .getListv16({ GID })
          .then((data) => {
            let { profileMap, members, totalMembers } = data;
            setAllGroupMembers({ profileMap, members, totalMembers });
          })
          .catch((err) => {
            // console.log(err);
          });
      }
      setIsLoading(false);
      return true;
    },
    banUserHandler: async (member) => {
      // console.log("In banUserHandler : ", member);
      setIsLoading(true);
      let mpid = member.pid;
      let textToAlert = "Are you sure you want to ban a user?";
      if (window.confirm(textToAlert) == true) {
        let response = await apicalls.banUser(PID, GID, mpid);
      }
      setIsLoading(false);
      setIfChanged(true);
      await apicalls
        .getListv16({ GID })
        .then((data) => {
          let { profileMap, members, totalMembers } = data;
          setAllGroupMembers({ profileMap, members, totalMembers });
        })
        .catch((err) => {
          // console.log(err);
        });

      return true;
    },
    unBanUserHandler: async (member) => {
      // console.log("In unBanUserHandler : ", member);
      setIsLoading(true);
      let mpid = member.pid;
      let textToAlert = "Are you sure you want to unban a user?";
      if (window.confirm(textToAlert) == true) {
        let response = await apicalls.unBanUser(PID, GID, mpid);
      }
      setIsLoading(false);
      setIfChanged(true);
      await apicalls
        .getListv16({ GID })
        .then((data) => {
          let { profileMap, members, totalMembers } = data;
          setAllGroupMembers({ profileMap, members, totalMembers });
        })
        .catch((err) => {
          // console.log(err);
        });

      return true;
    },
    deleteUserHandler: async (member) => {
      // console.log("In deleteUserHandler : ", member);
      setIsLoading(true);
      let mpid = member.pid;
      let textToAlert = "Are you sure you delete a user from the group?";
      if (window.confirm(textToAlert) == true) {
        let response = await apicalls.deleteUser(PID, GID, mpid);
      }
      setIsLoading(false);
      setIfChanged(true);
      await apicalls
        .getListv16({ GID, PID })
        .then((data) => {
          let { profileMap, members, totalMembers } = data;
          setAllGroupMembers({ profileMap, members, totalMembers });
        })
        .catch((err) => {
          // console.log(err);
        });

      return true;
    },
    handleScroll: (event) => {
      const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
      if (
        scrollTop < 20 &&
        !isLoading &&
        !stopAnotherApiCall &&
        lockScroll == false
      ) {
        if (allMessages.length > 5) {
          console.log("In if of handleScroll!");
          setIfScrolledUp(true);
        }
      }
    },
    handleUploadedImage: function (file) {
      // // console.log("File selected: ", file);
      setUploadedFile(file || "");
      setFileName(file.name || "");
      return;
    },
    fileUpload: function () {
      // // console.log("In file upload func, opening modal...");
      setToggleUploadImage(true);
      return true;
    },
    handleUploadedDoc: function (file) {
      console.log("Doc selected: ", file);
      setUploadedDoc(file || "");
      setFileName(file.name || "");
      return;
    },
    docUpload: (event) => {
      // console.log("In docUpload!");
      setToggleUploadDoc(true);
    },
    sendMessage: async (event) => {
      // console.log("\n\n\n\n\n\n --In sendMessage!", newMessage, " -- \n\n");
      if (newMessage.trim().length <= 0) {
        return true;
      }
      if (navigator.onLine === false) {
        alert("No internet connection available!");
        return true;
      }
      divRef.current.scrollIntoView({ behavior: "smooth" });
      var pid = profileData._id;
      var name = profileData.nam;
      var time = Date.now();
      var pi = morphProfilePicUrlToIdentifier(pid, profileData.ppic);
      var seens = 0;
      var mid = await generateMid(pid, GID, Date.now());
      setIfMessageSent({
        status: false,
        mid,
      });

      var msg = {
        mid: mid,
        body: newMessage,
        type: message_type.text,
        pid: pid,
        pnam: name,
        gid: GID,
        tim: time,
        pi: pi,
        seens: seens,
        ppic: profileData.ppic,
      };
      console.log({ isConnected });
      isConnected &&
        socket.emit(GROUP_MESSAGE_WEB, msg, function (res) {
          console.log(
            "In emit:",
            GROUP_MESSAGE_WEB,
            ", Is message sent : ",
            res
          );
          setTimeout(() => {
            setIfMessageSent({
              status: true,
              mid,
            });
          }, 1000);
        });
      if (isConnected === false) {
        msg.sent = false;
      } else {
        msg.sent = true;
      }
      msg.from = pid;
      // // console.log({ allMessages });
      setAllMessages([...allMessages, ...newMessagesForUser, msg]);
      scrollToBottom();
      setNewMessage("");

      apicalls
        .updateLastSeen({ GID })
        .then((data) => {
          setNewMessagesForUser([]);
          // setAllMessages([ ...allMessages, ...newMessagesForUser])
          setRemoveNewMessage(true);
        })
        .catch((err) => {
          setNewMessagesForUser([]);
          // setAllMessages([ ...allMessages, ...newMessagesForUser])
          setRemoveNewMessage(true);
          // // console.log({ updateLastSeenError: err });
        });
    },
    toggleShowSettingsHandler: (event) => {
      setToggleShowSettings(!toggleShowSettings);
    },
    toggleUploadImageHandler: (event) => {
      setToggleUploadImage(!toggleUploadImage);
      setFileName("");
      setUploadStatus(false);
    },
    toggleUploadDocHandler: (event) => {
      setToggleUploadDoc(!toggleUploadDoc);
    },
    handleGroupMemberPage: (index) => {
      if (index == currentGMPage) {
        // // console.log("We are on the same page only! Where tf u want to go?");
        return;
      }
      setCurrentGMPage(index);
      apicalls
        .getListv16({ GID, index })
        .then((data) => {
          // // console.log({ getListv16: data });
          let { profileMap, members, totalMembers } = data;
          setAllGroupMembers({ profileMap, members, totalMembers });
        })
        .catch((err) => {
          // // console.log({ getListv16Error: err });
        });
    },
  };

  // socket event handlers
  function onSocketConnect() {
    console.log(
      "SOCKET CONNECTED?",
      socket.connected,
      " or RECOVERED : ",
      socket.recovered
    );
    setIsConnected(true);
    socket &&
      socket.emit(
        INITIALIZE_WEB,
        {
          aid: aidInCookies,
          ver: "1.0",
          cl: "W",
        },
        function (socketRes) {
          // console.log({ socketRes });
          apicalls
            .updateLastSeen({ GID })
            .then((data) => {
              // // console.log({ updateLastSeen: data });
            })
            .catch((err) => {
              // // console.log({ updateLastSeenError: err });
            });
        }
      );
    setTimeout((t) => {
      setIfPreviouslyConnected(true);
    }, 1000);
  }
  function onSocketDisconnect(reason, details) {
    // alert("Socket disconnected!");
    console.log(
      "\n\n\n\n\n\n\n\n\n\nSocket disconnected!\n\n\n\n\n\n\n\n\n\n\n\n\n"
    );
    setIsConnected(false);
  }
  function onSocketMessage(m, signed_url) {
    // console.log("Recieved message handleSocketMessageWithMedia: ", m);
    setAllMessages((allMessages) => {
      if (m.gid == GID) {
        if (m.pid == PID) {
          m["self"] = true;
          m["sent"] = true;
        } else {
          m["self"] = false;
        }
        m["seens"] = 0;

        // // console.log(" -- Here in group_id check -- ");
        let msg = messageHandlers.processPpic(m);

        if (msg.type == 5) {
          messageHandlers.typeFiveHandler(msg);
        } else if (
          msg.type == 4 ||
          (msg.type === 1 && msg.fob) ||
          msg.type === 2 ||
          msg.type === "2"
        ) {
          msg.media_url = signed_url;
        }
        let newMessages = allMessages;

        if (msg.pid === PID) {
          // // console.log("My message only!");
        } else {
          console.log("Final message object in onSocketMessage", msg);
          newMessages = [...allMessages, msg];
          // // console.log({ newMessages });
        }
        if (msg.type === 2) {
          const uniqueImages = new Set([...imagesState, msg]);
          // Convert back to array
          const uniqueImagesArray = Array.from(uniqueImages);
          console.log(uniqueImagesArray, "images in socket image message");
          // Set the state with unique images
          setImagesState(uniqueImagesArray);
        }
        return newMessages;
      }
    });
    apicalls
      .updateLastSeen({ GID })
      .then((data) => {
        setNewMessagesForUser([]);
        // setAllMessages([ ...allMessages, ...newMessagesForUser])
        setRemoveNewMessage(true);
      })
      .catch((err) => {
        setNewMessagesForUser([]);
        // setAllMessages([ ...allMessages, ...newMessagesForUser])
        setRemoveNewMessage(true);
        // // console.log({ updateLastSeenError: err });
      });
    divRef.current.scrollIntoView({ behavior: "smooth" });
  }
  // socket event handlers

  const messageHandlers = {
    sendDocMessage: async function (mid, url, file_name, filesize, ext) {
      var extCodes = {
        pdf: 1,
        ppt: 2,
        pptx: 3,
        xls: 4,
        xlsx: 5,
        doc: 6,
        docx: 7,
        txt: 8,
      };
      var pid = PID;
      var name = profileData.nam;
      var gid = GID;
      var time = Date.now();
      var pi = morphProfilePicUrlToIdentifier(PID, profileData.ppic);

      var msg = {
        mid: mid,
        type: message_type.text,
        pid: pid,
        body: "**This message requires a latest version of Imarticus Learning. Please upgrade your app to view this message. https://imarticus.org/ **",
        pnam: name,
        gid: gid,
        tim: time,
        pi: pi,
        fob: {
          fn: file_name,
          ft: extCodes[ext],
          fu: url,
          fs: filesize,
          fc: "caption",
          fe: "",
          ful: "",
        },
      };
      console.log(
        "In send doc Api etc are all good! Url is there! Just need to transform it into a message.",
        { msg }
      );
      socket.emit("g_m_w", msg, function () {
        msg["self"] = true;
        msg["sent"] = true;
        if (newMessagesForUser.length > 0) {
          setAllMessages([...allMessages, ...newMessagesForUser, msg]);
        } else {
          setAllMessages([...allMessages, msg]);
        }
      });
      return true;
    },
    sendImageMessage: async function (mid, url) {
      var pid = PID;
      var name = profileData.nam;
      var gid = GID;
      var time = Date.now();
      var pi = morphProfilePicUrlToIdentifier(pid, profileData.ppic);

      var msg = {
        mid: mid,
        body: url,
        type: message_type.image,
        pid: pid,
        pnam: name,
        gid: gid,
        tim: time,
        pi: pi,
      };

      // updateLastSeen();
      // console.log("Before chatMedia :: ", { pid, gid, url });
      let res = await messageHandlers.processMyImage(pid, gid, url);
      // console.log("msg from Api chatMedia :: ", res);
      msg["media_url"] = res;

      socket.emit("g_m_w", msg, function () {
        msg["self"] = true;
        msg["sent"] = true;
        if (newMessagesForUser.length > 0) {
          setAllMessages([...allMessages, ...newMessagesForUser, msg]);
        } else {
          setAllMessages([...allMessages, msg]);
        }
        const uniqueImages = new Set([...imagesState, msg]);
        const uniqueImagesArray = Array.from(uniqueImages);
        console.log(uniqueImagesArray, "in emit of image!");
        setImagesState(uniqueImagesArray);
      });
      return true;
    },
    processMyImage: async function (pid, gid, url) {
      return await apicalls.chatMedia(pid, GID, url);
    },
    uploadFile: async function () {
      console.log("\n\n\n\nIn uploadImage!");
      let file = uploadedFile;
      let maxImgSizeAllowed = 5 * 1024 * 1024;
      if (file == null || !file) {
        return alert("Please select a file to upload!");
      } else if (file.size > maxImgSizeAllowed) {
        alert("File size too large!");
        return "";
      }
      if (uploadStatus !== false) {
        return false;
      }
      console.log("File : ", file);
      setUploadStatus("Uploading...!");
      setFileName(file.name);
      var splits = file.name.split(".");
      var ext = splits[splits.length - 1].toLowerCase() || "jpeg";
      if (ext === "png") {
        alert("Please jpeg or jpg files only!");
      }
      var newImageMid = await generateMid(PID, GID, Date.now());
      var fileName = newImageMid + "." + ext;

      try {
        let s3Params = await apicalls.fetchMediaPolicy(PID, GID, fileName);
        // // console.log("Response of fetchMediaPolicy :: ", s3Params);

        let res = await apicalls.uploadFile({
          s3Params,
          file,
          fileType: file.type,
          fileName,
          ext,
          newImageMid,
        });
        // console.log("UploadFile in res: ", res);
        await messageHandlers.sendImageMessage(res.newImageMid, res.url);
        setUploadStatus("Uploaded successfully...!");
        setFileName("");
        setTimeout(() => setUploadStatus(false), 3000);
        setToggleUploadImage(!toggleUploadImage);
        setTimeout(() => {
          scrollToBottom();
        }, 1000);
      } catch (err) {
        console.log("The error in uploadDoc uploadFile:: ", { err });
        setUploadStatus("Failed");
        setFileName("");
        setTimeout(() => setUploadStatus(false), 3000);
      }
    },
    uploadDoc: async function () {
      console.log("\n\n\n\nIn uploadDoc!");
      let file = uploadedDoc;
      const maxDocSizeAllowed = 1 * 1024 * 1024;
      // console.log(file.size);
      if (file == null) {
        return alert("Please select a file to upload!");
      } else if (file.size > maxDocSizeAllowed) {
        alert("File size too large!");
        return "";
      }
      console.log("uploadStatus: ", uploadStatus);
      if (uploadStatus !== false) {
        return false;
      }
      console.log("file : ", file);
      setUploadStatus("Uploading...!");
      setFileName(file.name);
      var splits = file.name.split(".");
      var ext = splits[splits.length - 1].toLowerCase();
      var newImageMid = await generateMid(PID, GID, Date.now());
      var fileName = newImageMid + "." + ext;
      var fileTypes = {
        "application/pdf": "pdf",
        "application/vnd.ms-powerpoint": "ppt",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          "pptx",
        "application/vnd.ms-excel": "xls",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          "xlsx",
        "text/plain": "txt",
        "application/msword": "doc",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          "docx",
      };

      let s3Params = await apicalls.fetchDocPolicy(
        PID,
        GID,
        fileTypes[file.type],
        fileName
      );
      // console.log("Response of fetchMediaPolicy :: ", s3Params);
      try {
        let res = await apicalls.uploadFile({
          s3Params,
          file,
          fileType: file.type,
          fileName,
          ext,
          newImageMid,
        });
        console.log("UploadDoc in res: ", res);

        let messageSent = await messageHandlers.sendDocMessage(
          res.newImageMid,
          res.url,
          fileName,
          file.size,
          ext
        );
        console.log({ messageSent });
        setUploadStatus("Uploaded successfully...!");
        setTimeout(() => setUploadStatus(false), 3000);
        setToggleUploadDoc(!toggleUploadDoc);
        setFileName("");
        scrollToBottom();
      } catch (err) {
        console.log("The error in uploadDoc uploadFile:: ", { err });
        setUploadStatus("");
        setFileName("");
        setTimeout(() => setUploadStatus(false), 3000);
      }
    },
    processPpic: function (message) {
      // // console.log("In processPpic : ", message);
      message.ppic = message.pi
        ? PROFILE_PIC_BASE + message.pi + ".jpg"
        : blank_profile_pic;
      // // console.log({ returningMessagePpic: message.ppic });
      return message;
    },
    processRole: function (message) {
      // // console.log("In processRole : ", message);
      if (message.type != 5) {
        // // console.log({ allGroupMembers: allGroupMembers });
        // // console.log({ allGroupMembers_ProfileMap: allGroupMembers.profileMap });
        // // console.log(
        //   "allGroupMembers.profileMap[message.from || message.pid] : ",
        //   allGroupMembers.profileMap[message.from || message.pid]
        // );
        if (allGroupMembers.profileMap[message.from || message.pid].role) {
          message.role =
            allGroupMembers.profileMap[message.from || message.pid].role;
          // // console.log({ returnedMessageProcessRole: message });
          return message;
        }
      }
    },
    processImage: function (message) {
      if (
        message.type == message_type["image"] ||
        message.type == message_type["audio"]
      ) {
        messageHandlers.loadMedia(message, function (message) {
          //on success only
          if (message.type == message_type["image"]) {
            imagesState.push({
              url: message.media_url,
              caption: currentGroup.name,
            });
            let images_indx = {};
            images_indx[message._id] = imagesState.length - 1;
            // setImagesIndex({ ...imagesIndex, images_indx });
          }
        });
      }
    },
    loadMedia: function (message, cb) {
      if (message && !message["media_url"]) {
        return apicalls
          .fetchImageLoadUrl(GID, message.body)
          .then((res) => {
            //success
            message["media_url"] = res.signed_url;
            return cb(message);
          })
          .catch((error) => {
            //fail
            // // console.log(
            //   "Error occured in loadMedia for discussion message: " + error
            // );
            return cb(null);
          });
      }
      return cb(null);
    },
    typeFiveHandler: function (data) {
      if (data.body == "Now, only admins can post into this group") {
        setIfCommEnabled(false);
      } else if (
        data.body == "Now all the members can post messages in this group"
      ) {
        if (currentGroupMember.type == 3) {
          setIfCommEnabled(false);
        }
        setIfCommEnabled(true);
        // $scope.enable_comm = true;
        // $scope.gtyp = true;
      }
    },
  };

  async function generateMid(pid, GID, time) {
    return sha256(pid + GID + time + Math.floor(Math.random() * 10000));
  }
  function morphProfilePicUrlToIdentifier(pid, url) {
    if (!pid || !url || url == blank_profile_pic) {
      return 0;
    }
    try {
      var splits = url.split("/");
      var file_name = splits[splits.length - 1];
      // // console.log("FileName :: ", file_name);
      if (file_name.split(".").length > 0) {
        return file_name.split(".")[0].replace(pid, "").toString();
      } else {
        return file_name.toString();
      }
    } catch (e) {
      // console.log("morphProfilePicUrlToIdentifier error: ", e);
      return 0;
    }
  }

  let getTime = function (time) {
    let date = new Date(time);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
  };
  let checkMsgStatus = function (message, index) {
    // if (socket.connected){
    // // console.log("in checkmsgstatus  : ", message, ifMessageSent)
    if (message.mid == ifMessageSent.mid && message.pid === PID) {
      if (ifMessageSent.status || message.sent == true) return tick;
      else if (socket.connected === false) return alertIcon;
      else return clock;
    } else {
      // // console.log("In else of checkmsgstatus!")
      if (message.sent === false) return alertIcon;
      else return tick;
    }
    // } else {
    //   return alertIcon
    // }
  };
  const fileWiseImage = {
    1: pdf,
    2: ppt,
    3: ppt,
    4: xls,
    5: xls,
    6: doc,
    7: doc,
    8: txt,
  };

  const getFileType = (fileUrl) => {
    let splitArray = fileUrl.split(".");
    let lastSplit = splitArray[splitArray.length - 1];
    return lastSplit;
  };

  const fetchProfilePicOfUser = (pid) => {
    // // console.log({ pid });
    return (
      (allGroupMembers &&
        allGroupMembers.profileMap[pid] &&
        allGroupMembers.profileMap[pid].ppic) ||
      ""
    );
  };
  console.log({
    ifPreviouslyConnected,
    allMessages,
    newMessagesForUser,
    imagesState,
  });
  if (isLoading)
    return (
      <div className="chatScss">
        <div
          className={`dashboard-content-wrapper ${isLoading ? "loading" : ""}`}
          style={{
            marginLeft: "250px",
            paddingBottom: "10px",
            overflowY: "scroll",
          }}
        >
          <div
            className="page-header eck-header top-block make-abs"
            style={{ width: "calc(100% - 250px)" }}
          >
            <div className="dummy_message_heading">
              <div className="skeleton-text-heading"></div>
              <div className="skeleton-text-small"></div>
            </div>
          </div>
          <div className="chat-container">
            <div className="dummy_message">
              <div className="skeleton-avatar"></div>
              <div className="skeleton-text"></div>
            </div>
            <div className="dummy_message">
              <div className="skeleton-avatar"></div>
              <div className="skeleton-text"></div>
            </div>
            <div className="dummy_message">
              <div className="skeleton-avatar"></div>
              <div className="skeleton-text"></div>
            </div>
            <div className="dummy_message">
              <div className="skeleton-avatar"></div>
              <div className="skeleton-text"></div>
            </div>
            <div className="dummy_message">
              <div className="skeleton-avatar"></div>
              <div className="skeleton-text"></div>
            </div>
            <div className="dummy_message">
              <div className="skeleton-avatar"></div>
              <div className="skeleton-text"></div>
            </div>
            <div className="dummy_message">
              <div className="skeleton-avatar"></div>
              <div className="skeleton-text"></div>
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <div className="chatScss">
      {/* <CourseHeader
          breadCrumbs={[{ name: courseName, link: `/view?cid=${cid}` }]}
          individualBrand={""}
        /> */}
      <SidebarContainer crs_pg_id={props.selected_course.crs_pg_id} />
      <div
        className={`dashboard-content-wrapper chatbox ${
          isLoading ? "loading" : ""
        }`}
        style={{
          marginLeft: "250px",
          paddingBottom: "10px",
          overflowY: "scroll",
        }}
        onScroll={eventHandlers.handleScroll}
      >
        {isLoading && <div className="loader"></div>}
        {toggleShowSettings && (
          <GroupMembers
            currentGMPage={currentGMPage}
            handleGroupMemberPage={eventHandlers.handleGroupMemberPage}
            PID={PID}
            allGroupMembers={allGroupMembers}
            currentGroup={currentGroup}
            toggleShowSettings={toggleShowSettings}
            toggleShowSettingsHandler={eventHandlers.toggleShowSettingsHandler}
            currentGroupMember={currentGroupMember}
            makeAdminHandler={eventHandlers.makeAdminHandler}
            removeAdminHandler={eventHandlers.removeAdminHandler}
            banUserHandler={eventHandlers.banUserHandler}
            unbanUserHandler={eventHandlers.unBanUserHandler}
            deleteUserHandler={eventHandlers.deleteUserHandler}
          />
        )}
        {toggleUploadImage && (
          <UploadImage
            PID={PID}
            toggleUploadImage={toggleUploadImage}
            toggleUploadImageHandler={eventHandlers.toggleUploadImageHandler}
            uploadFile={messageHandlers.uploadFile}
            handleUploadedImage={eventHandlers.handleUploadedImage}
            uploadStatus={uploadStatus}
            fileName={fileName}
          />
        )}
        {toggleUploadDoc && (
          <UploadDoc
            PID={PID}
            toggleUploadDoc={toggleUploadDoc}
            toggleUploadDocHandler={eventHandlers.toggleUploadDocHandler}
            uploadDoc={messageHandlers.uploadDoc}
            handleUploadedDoc={eventHandlers.handleUploadedDoc}
            uploadStatus={uploadStatus}
            fileName={fileName}
          />
        )}
        {togglePreviewImages && (
          <PreviewImage
            openImage={eventHandlers.openImage}
            imagesState={imagesState}
            togglePreviewImages={togglePreviewImages}
            currentImageIndex={currentImageIndex}
            toggleImagePreview={eventHandlers.toggleImagePreview}
            // togglePreviewImageHandler={togglePreviewImageHandler}
          />
        )}

        <div
          className="page-header eck-header top-block make-abs"
          style={{ width: "calc(100% - 250px)" }}
        >
          <div
            className="row w-100"
            style={{
              margin: "0px",
              paddingTop: "20px",
              backgroundColor: "#fff",
            }}
          >
            <div className="col-9">
              <div className="eck-bc-header eck-bc-header-left">
                <h3 className="eck-bc-head eck-nty-body">
                  <span>
                    <a href="#" ng-click="redirectToGroupDetailPage()">
                      <i className="icon ion-chevron-left head-left-icon"></i>
                    </a>
                  </span>
                  {currentGroup.name}
                </h3>
              </div>
            </div>
            <div className="col-3">
              <div
                className="eck-bc-header-right"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "25px",
                }}
              >
                {/* <i ng-show="ifNotificationHidden" ng-click="toggleRightBar()" className="icon ion-ios-skipbackward notification-toggle"></i> */}
                {/* <div className= {`chatchat ${isConnected ? `chatOnline` : `chatOffline`}`}>
                </div>  */}
                <img
                  src={settings}
                  onClick={eventHandlers.toggleShowSettingsHandler}
                  width="30px"
                />
                {/* <i ng-click="showSettings = !showSettings" style={{float: "right",margin: "16px 1px"}} className="icon ion-ios-gear icon-options"></i> */}
              </div>
            </div>
          </div>
        </div>
        {allMessages && allMessages.length > 0 ? (
          allMessages.map((msg, index) => {
            return (
              <>
                {msg.type == 1 &&
                  !msg.fob && ( // Normal message
                    <>
                      {(msg.from || msg.pid) !== PID ? (
                        <div className="row w-100" key={index}>
                          {/* <div className="col-1">
                        </div> */}
                          <div
                            className="col-7"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={
                                fetchProfilePicOfUser(msg.pid || msg.from) ||
                                blank_profile_pic
                              }
                              style={{
                                width: "60px",
                                background: "white",
                                borderRadius: "30px",
                                marginLeft: "15px",
                                padding: "4px",
                              }}
                            />
                            <div class="card studentsMessage">
                              <div class="card-body">
                                <h5 class="card-title">{msg.pnam}</h5>
                                {/* <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
                                <p class="card-text cardText">{msg.body}</p>
                                <small style={{ float: "right" }}>
                                  {(msg.body.length > 10
                                    ? "\u00A0\u00A0"
                                    : "") + getTime(msg.tim)}
                                </small>
                                {/* <a href="#" class="card-link">Card link</a>
                            <a href="#" class="card-link">Another link</a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row w-100">
                          <div className="col-5" style={{ marginLeft: "auto" }}>
                            <div class="card myMessage">
                              <div class="card-body">
                                <p class="card-text cardText">{msg.body}</p>
                                <small style={{ float: "right" }}>
                                  {getTime(msg.tim)} &nbsp;
                                  <img
                                    style={{ width: "10px" }}
                                    src={checkMsgStatus(msg, index)}
                                  />
                                </small>
                                {/* <a href="#" class="card-link">Card link</a>
                              <a href="#" class="card-link">Another link</a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                {msg.type == 1 &&
                  msg.fob && ( // Doc message
                    <>
                      {(msg.from || msg.pid) !== PID ? (
                        <div className="row w-100" key={index}>
                          <div
                            className="col-10"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={
                                fetchProfilePicOfUser(msg.pid || msg.from) ||
                                blank_profile_pic
                              }
                              style={{
                                width: "60px",
                                background: "white",
                                borderRadius: "30px",
                                marginLeft: "15px",
                                padding: "4px",
                              }}
                            />
                            <div className="card studentsMessage">
                              <div className="card-body">
                                <h5 className="card-title">{msg.pnam}</h5>
                                <div
                                  style={{
                                    display: "inline-block",
                                    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                                    padding: "10px",
                                    width: "420px",
                                  }}
                                >
                                  <FileViewer
                                    fileType={getFileType(msg.fob.fu)}
                                    filePath={msg.fob.fu}
                                  />
                                  <a
                                    style={{
                                      display: "inline",
                                      color: "black",
                                      marginRight: "10px",
                                    }}
                                    href={msg.fob.fu}
                                    target="_blank"
                                  >
                                    <p
                                      style={{
                                        display: "inline",
                                        color: "black",
                                        marginRight: "5px",
                                        wordBreak: "break-all",
                                      }}
                                      className="card-text"
                                    >
                                      <img
                                        style={{
                                          display: "inline",
                                          width: "25px",
                                        }}
                                        src={fileWiseImage[msg.fob.ft]}
                                      />
                                      &nbsp;&nbsp;
                                      {msg.fob.fn}
                                      &nbsp;&nbsp;
                                      <img
                                        id="download"
                                        style={{ width: "20px" }}
                                        src={download}
                                      />
                                    </p>
                                  </a>
                                  <small style={{ float: "right" }}>
                                    {getTime(msg.tim)}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row w-100" key={index}>
                          <div className="col-7" style={{ marginLeft: "auto" }}>
                            <div className="card myMessage">
                              <div className="card-body">
                                {/* <h5 className="card-title">{msg.pnam}</h5> */}
                                <div
                                  style={{
                                    display: "inline-block",
                                    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                                    padding: "10px",
                                    width: "420px",
                                  }}
                                >
                                  <FileViewer
                                    fileType={getFileType(msg.fob.fu)}
                                    filePath={msg.fob.fu}
                                  />
                                  <a
                                    style={{
                                      display: "inline",
                                      color: "black",
                                      marginRight: "10px",
                                    }}
                                    href={msg.fob.fu}
                                    target="_blank"
                                  >
                                    <p
                                      style={{
                                        display: "inline",
                                        color: "black",
                                        marginRight: "5px",
                                        wordBreak: "break-all",
                                      }}
                                      className="card-text"
                                    >
                                      <img
                                        style={{
                                          display: "inline",
                                          width: "25px",
                                        }}
                                        src={fileWiseImage[msg.fob.ft]}
                                      />
                                      &nbsp;&nbsp;
                                      {msg.fob.fn}
                                      &nbsp;&nbsp;
                                      <img
                                        id="download"
                                        style={{ width: "20px" }}
                                        src={download}
                                      />
                                    </p>
                                  </a>
                                  <small style={{ float: "right" }}>
                                    {getTime(msg.tim)}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                {msg.type == 2 && (
                  <>
                    {(msg.from || msg.pid) !== PID ? (
                      <div className="row w-100">
                        <div
                          className="col-7"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src={
                              fetchProfilePicOfUser(msg.pid || msg.from) ||
                              blank_profile_pic
                            }
                            style={{
                              width: "60px",
                              background: "white",
                              borderRadius: "30px",
                              marginLeft: "15px",
                              padding: "4px",
                            }}
                          />
                          <div class="card studentsMessage">
                            <h5 className="card-title">{msg.pnam}</h5>
                            <img
                              onClick={(e) => eventHandlers.openImage(e, msg)}
                              className="media-message"
                              style={{ height: "300px", width: "300px" }}
                              src={
                                msg.media_url
                                // ||
                                // messageHandlers.processMyImage(msg.body)
                              }
                            />
                            <small style={{ alignSelf: "end" }}>
                              {getTime(msg.tim)}
                            </small>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row w-100">
                        <div className="col-5" style={{ marginLeft: "auto" }}>
                          <div class="card myMessage">
                            <img
                              onClick={(e) => eventHandlers.openImage(e, msg)}
                              className="media-message"
                              style={{ height: "300px", width: "300px" }}
                              src={
                                msg.media_url
                                //  ||
                                // messageHandlers.processMyImage(msg.body)
                              }
                            />
                            <small style={{ alignSelf: "end" }}>
                              {getTime(msg.tim)}
                            </small>
                            {/* <img src={processImage(msg.body)} /> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {msg.type == 5 && ( // Notification message
                  <div className="row" style={{ margin: 0 }}>
                    <div className="col-md-12 col-xs-12">
                      <div className="msg-update">
                        <span className="msg-update-content">
                          <i className="icon ion-android-notifications eck-icon-right-pad"></i>
                          <span>
                            <img
                              style={{ width: "18px", marginRight: "10px" }}
                              src={
                                "https://cdn.pegasus.imarticus.org/index/notifications_neutral.svg"
                              }
                            />
                            {msg.body}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                  </div>
                )}
                {msg.type == 4 && ( //audio message
                  <>
                    {(msg.from || msg.pid) !== PID ? (
                      <div className="row w-100">
                        <div
                          className="col-7"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src={
                              fetchProfilePicOfUser(msg.pid || msg.from) ||
                              blank_profile_pic
                            }
                            style={{
                              width: "60px",
                              background: "white",
                              borderRadius: "30px",
                              marginLeft: "15px",
                              padding: "4px",
                            }}
                          />
                          <div class="card studentsMessage">
                            <h5 className="card-title">{msg.pnam}</h5>
                            <a href={msg.media_url}>Download Audio</a>
                            <small style={{ alignSelf: "end" }}>
                              {getTime(msg.tim)}
                            </small>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row w-100">
                        <div className="col-5" style={{ marginLeft: "auto" }}>
                          <div class="card myMessage">
                            <a href={msg.media_url}>Download Audio</a>
                            <small style={{ alignSelf: "end" }}>
                              {getTime(msg.tim)}
                            </small>
                            {/* <img src={processImage(msg.body)} /> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            );
          })
        ) : (
          <div>
            <p>No messages to show!</p>
          </div>
        )}
        {!removeNewMessage && newMessagesForUser.length > 0 && (
          <>
            <div
              className="row"
              style={{ alignItems: "center", marginTop: "38px" }}
            >
              <div className="new-message-line"></div>
              <div className="new-message-container">
                <div className="new-message">New Messages</div>
              </div>
            </div>
            {newMessagesForUser.map((msg, index) => {
              return (
                <>
                  {msg.type == 1 &&
                    !msg.fob && ( // Normal message
                      <div className="row w-100" key={index}>
                        {/* <div className="col-1">
                      </div> */}
                        <div
                          className="col-7"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src={
                              fetchProfilePicOfUser(msg.pid || msg.from) ||
                              blank_profile_pic
                            }
                            style={{
                              width: "60px",
                              background: "white",
                              borderRadius: "30px",
                              marginLeft: "15px",
                              padding: "4px",
                            }}
                          />
                          <div class="card studentsMessage">
                            <div class="card-body">
                              <h5 class="card-title">{msg.pnam}</h5>
                              {/* <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
                              <p class="card-text cardText">{msg.body}</p>
                              <small style={{ float: "right" }}>
                                {(msg.body.length > 10 ? "\u00A0\u00A0" : "") +
                                  getTime(msg.tim)}
                              </small>
                              {/* <a href="#" class="card-link">Card link</a>
                          <a href="#" class="card-link">Another link</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {msg.type == 1 &&
                    msg.fob && ( // Doc message
                      <div className="row w-100" key={index}>
                        <div
                          className="col-10"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src={
                              fetchProfilePicOfUser(msg.pid || msg.from) ||
                              blank_profile_pic
                            }
                            style={{
                              width: "60px",
                              background: "white",
                              borderRadius: "30px",
                              marginLeft: "15px",
                              padding: "4px",
                            }}
                          />
                          <div className="card studentsMessage">
                            <div className="card-body">
                              <h5 className="card-title">{msg.pnam}</h5>
                              <div
                                style={{
                                  display: "inline-block",
                                  boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                                  padding: "10px",
                                  width: "420px",
                                }}
                              >
                                <FileViewer
                                  fileType={getFileType(msg.fob.fu)}
                                  filePath={msg.fob.fu}
                                />
                                <a
                                  style={{
                                    display: "inline",
                                    color: "black",
                                    marginRight: "10px",
                                  }}
                                  href={msg.fob.fu}
                                  target="_blank"
                                >
                                  <p
                                    style={{
                                      display: "inline",
                                      color: "black",
                                      marginRight: "5px",
                                      wordBreak: "break-all",
                                    }}
                                    className="card-text"
                                  >
                                    <img
                                      style={{
                                        display: "inline",
                                        width: "25px",
                                      }}
                                      src={fileWiseImage[msg.fob.ft]}
                                    />
                                    &nbsp;&nbsp;
                                    {msg.fob.fn}
                                    &nbsp;&nbsp;
                                    <img
                                      id="download"
                                      style={{ width: "20px" }}
                                      src={download}
                                    />
                                  </p>
                                </a>
                                <small style={{ float: "right" }}>
                                  {getTime(msg.tim)}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {msg.type == 2 && (
                    <div className="row w-100">
                      <div
                        className="col-7"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          onClick={(e) => eventHandlers.openImage(e, msg)}
                          src={
                            fetchProfilePicOfUser(msg.pid || msg.from) ||
                            blank_profile_pic
                          }
                          style={{
                            width: "60px",
                            background: "white",
                            borderRadius: "30px",
                            marginLeft: "15px",
                            padding: "4px",
                          }}
                        />
                        <div class="card studentsMessage">
                          <img
                            onClick={(e) => eventHandlers.openImage(e, msg)}
                            className="media-message"
                            style={{ height: "300px", width: "300px" }}
                            src={
                              msg.media_url
                              // ||
                              // messageHandlers.processMyImage(msg.body)
                            }
                          />
                          <small style={{ alignSelf: "end" }}>
                            {getTime(msg.tim)}
                          </small>
                        </div>
                      </div>
                    </div>
                  )}
                  {msg.type == 4 && (
                    <div className="row w-100">
                      <div
                        className="col-7"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={
                            fetchProfilePicOfUser(msg.pid || msg.from) ||
                            blank_profile_pic
                          }
                          style={{
                            width: "60px",
                            background: "white",
                            borderRadius: "30px",
                            marginLeft: "15px",
                            padding: "4px",
                          }}
                        />
                        <div class="card studentsMessage">
                          <h5 className="card-title">{msg.pnam}</h5>
                          <a href={msg.media_url}>Download Audio</a>
                          <small style={{ alignSelf: "end" }}>
                            {getTime(msg.tim)}
                          </small>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </>
        )}
        <div ref={divRef} />
        {ifCommEnabled == false ? (
          ""
        ) : (
          <div className="shootpad">
            {" "}
            {/* will only be enabled when enable_comm is true */}
            <div className="row" style={{ margin: "0" }}>
              <div
                className="col-lg-1 col-md-1 col-sm-2 col-xs-2 "
                style={{ padding: "0 2px 0 2px", cursor: "pointer" }}
              >
                <div className="centerdIcons">
                  <div
                    className="shootpad-left cameraIcon"
                    onClick={eventHandlers.fileUpload}
                  >
                    <img src={cameraIcon} />
                  </div>
                  <div
                    className="shootpad-rightt attachIcon"
                    onClick={eventHandlers.docUpload}
                  >
                    <img src={attachmentIcon} width="30px" />
                  </div>
                </div>
              </div>

              <div
                className="col-lg-10 col-md-10 col-sm-8 col-xs-8"
                style={{ padding: 0 }}
              >
                <div className="shootpad-main">
                  <textarea
                    auto-grow=""
                    rows="1"
                    wrap="hard"
                    placeholder="Type your message..."
                    className="ng-pristine ng-untouched ng-valid"
                    style={{ height: "22px" }}
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode == 13 && !e.shiftKey) {
                        e.preventDefault();
                        eventHandlers.sendMessage();
                      }
                    }}
                  ></textarea>
                </div>
              </div>

              <div
                className="col-lg-1 col-md-1 col-sm-2 col-xs-2"
                style={{ padding: 0, cursor: "pointer" }}
                onClick={eventHandlers.sendMessage}
              >
                <div className="shootpad-right">
                  <img
                    src={
                      "https://cdn.pegasus.imarticus.org/index/chat_send_neutral.svg"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profileName: state.profile.p_name,
  email: state.primaryEmail.secondary_emails[0],
  primaryEmail: state.profile.email,
  ppic: state.profile.ppic,
});

const mapDispatchToProps = {
  dispatchIndividualBrand,
  getProfileInfoOnly,
  setSelectedCourse,
  setAttendance,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
