import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router";
import {
  BrowserRouter as Router,
  useLocation,
  withRouter,
} from "react-router-dom";
import { useParams } from "react-router-dom";
import "./styles/styles.css";
import Dashboard from "./components/dashboards/Dashboard";
import Course from "./components/course/course";
import Chapter from "./components/chapter/chapter";
import Gradebook from "./components/gradebook/gradebook";
import CareerServices from "./components/placement/placementportal";
import SingleJobView from "./components/placement/placementsinglejobview";
import Certificate from "./components/certificate/certficate";
import CertificateView from "./components/certificateview/certificateview";
import NotFound from "./components/errors/NotFound";
import { CONFIGS } from "./constants/constants";
import { utils } from "./utility/utils";
import Login from "./components/login/Login__New.js";
import Signup from "./components/login/Signup__New.js";
import LoginWithUsername from "./components/login/LoginWithUsername";
import LoginWithEmail from "./components/login/LoginWithEmail__New";
import LoginWithMobile from "./components/login/LoginWithMobile__New";
import ResetPassword from "./components/login/ResetPassword";
import LoginWithOtp from "./components/login/LoginWithOtp__New.js";
import { onMessageListener, getTokens } from "../src/firebase";
import Cookies from "universal-cookie";
import axios from "axios";
import UnsubscribeNotification from "./components/notifications/UnsubscribeNotification";
import { useDispatch, useSelector } from "react-redux";
import { updateEndpoints } from "./actions/primaryEmailActions";
import { logoutUser } from "./actions/accountDropdownAction";
import ZoomMeeting from "./components/zoom/zoom_meeting";
import homeCtrl from "./components/homeCtrl/homeCtrl";
import VerifyCertificate from "./components/verifyCertificate/verifycertificate";
import Auth from "./components/login/Auth";

import { dispatchIndividualBrand } from "./actions/brandAction";
import Attendance from "./components/attendance/attendance";
import MyCalendar from "./components/attendance/myCalendar";
let cookies = new Cookies();
// import { withInAppMessaging } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css';
// import { Analytics, Notifications } from 'aws-amplify';

// const { InAppMessaging } = Notifications;
// const myFirstEvent = { name: 'my_first_event' };

const App = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const tokenSent = useSelector((state) => state.primaryEmail.ifTokenSent);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  const [token, setToken] = useState(false);
  const [helpdeskInitiated, setHelpdeskInitiated] = useState(false);
  // const [tokenSent, setTokenSent] = useState(false);

  onMessageListener()
    .then((payload) => {
      console.log({ payload });
      setShow(true);
      setNotification({
        title: payload.data["pinpoint.notification.title"],
        body: payload.data["pinpoint.notification.body"],
      });
    })
    .catch((err) => console.log(err));

  var loggedIn = utils.getLoggedIn();
  if (loggedIn) {
    getTokens(setTokenFound, setToken);
  }
  if (isTokenFound) {
    var tokenObj = {};
    tokenObj.token = token;
    tokenObj.type = "SET_TOKEN";
    console.log("Setting token to " + tokenObj.token);
    dispatch(tokenObj);
  }
  console.log("Token found: ", token);
  console.log({ tokenSent });
  let isImarticusLink = () => {
    let url = new URL(window.location.href);
    return url.host?.includes("imarticus.org");
  };

  useEffect(() => {
    console.log("on route change");
    console.log({ location });
    let { pathname } = location;
    let brandData = cookies.get("brand");
    console.log({ brandData });
    if (!props.brand || !Object.keys(props.brand).length) {
      if (brandData && brandData != "undefined") {
        console.log({ brandData });
        // console.log({ 'JSON.parse(brandData)':JSON.parse(brandData) });
        if (typeof brandData == "string") brandData = JSON.parse(brandData);
        var brand = {
          themeColorIndex: brandData.themeColorIndex,
          ctaColorIndex: brandData.ctaColorIndex,
          loaderColorIndex: brandData.loaderColorIndex,
          textColor: brandData.textColor,
          pic: brandData.pic,
          name: brandData.name,
          whiteBackground: brandData.whiteBackground,
          exploreEnabled: brandData.exploreEnabled,
          getHelpEnabled: brandData.getHelpEnabled,
          bgimg: brandData.bgimg,
        };
        console.log({ brand });
      }
      props.dispatchIndividualBrand(brand);
    }
    console.log({
      "props.brand": props.brand,
      "props.brand&&Object.keys(props.brand).length":
        props.brand && Object.keys(props.brand).length,
    });
    if (!helpdeskInitiated) {
      if (
        !pathname.includes("/login") &&
        !pathname.includes("/forgot") &&
        !pathname.includes("/signup") &&
        !pathname.includes("/zoom_meeting") &&
        !pathname.includes("/vfcrt") &&
        !pathname.includes("/chapter")
      )
        checkAndInitiatehelpDesk();
    }
  }, [location]);
  let checkAndInitiatehelpDesk = () => {
    if (helpdeskInitiated) return;

    const script = document.createElement("script");

    script.src = "https://ind-widget.freshworks.com/widgets/88000000434.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    window.fwSettings = {
      widget_id: 88000000434,
    };
    console.log({
      "typeof window.FreshworksWidget": typeof window.FreshworksWidget,
    });
    setHelpdeskInitiated(true);
    if ("function" != typeof window.FreshworksWidget) {
      var n = function () {
        n.q.push(arguments);
      };
      n.q = [];
      window.FreshworksWidget = n;
    }
  };
  useEffect(() => {
    console.log({ "props.brand": props.brand });
  }, [props.brand]);
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/unsubscribeEmail"
          component={UnsubscribeNotification}
        />
        <Route exact path="/login" component={Login} />
        <Route exact path="/auth" component={Auth} />
        <Route exact path="/login/email" component={LoginWithEmail} />
        <Route exact path="/login/username" component={LoginWithUsername} />
        <Route exact path="/login/mobile" component={LoginWithMobile} />
        <Route exact path="/login/otp" component={LoginWithOtp} />
        <Route exact path="/forgot" component={ResetPassword} />
        {/* <Route exact path="/notifications" component={Notifications} /> */}
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/:brand/login" component={Login} />
        <Route exact path="/:brand/login/email" component={LoginWithEmail} />
        <Route
          exact
          path="/:brand/login/username"
          component={LoginWithUsername}
        />
        <Route exact path="/:brand/login/mobile" component={LoginWithMobile} />
        <Route exact path="/:brand/login/otp" component={LoginWithOtp} />
        <Route exact path="/:brand/forgot" component={ResetPassword} />
        <Route exact path="/:brand/signup" component={Signup} />
        <Route
          exact
          path={["/:brand/dashboard", "/:brand/dashboard/*"]}
          component={Dashboard}
        />
        <Route
          exact
          path={["/dashboard", "/dashboard/*"]}
          component={Dashboard}
        />
        <Route path={["/view", "/view/*"]} component={Dashboard} />
        <Route path={"/batch/:pid/:gid/:cid/:lvlId"} component={Course} />
        <Route path="/attendance/:pid/:gid/:cid" component={Attendance} />
        <Route path="/calendar/user/:pid/:gid/:cid" component={MyCalendar} />
        <Route path={["/gradebook", "/gradebook/*"]} component={Gradebook} />
        <Route path="/chapter/:pid/:gid/:cid/:lvlId/*" component={Chapter} />
        <Route
          path={["/placement-portal", "/placement-portal/*"]}
          component={CareerServices}
        />
        <Route path={["/job-view", "/job-view/*"]} component={SingleJobView} />
        <Route
          path={["/placement-module", "/placement-module/*"]}
          component={Dashboard}
        />
        <Route path="/certificate/:pid/:cid/:elec_id" component={Certificate} />
        <Route
          path="/certificateView/:pid/:cid/:elec_id"
          component={CertificateView}
        />
        <Route path="/vfcrt/:crtId" component={VerifyCertificate} />
        <Route path="/zoom_meeting" component={ZoomMeeting} />
        <Route path="/course_home" component={homeCtrl} />
        <Route
          exact
          path="/"
          render={() =>
            loggedIn ? (
              <Redirect push to="/dashboard" />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  brand: state.brand?.brand,
});

const mapDispatchToProps = {
  dispatchIndividualBrand,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
// export default withRouter(App);
// export default App;
// export default withInAppMessaging(App);
