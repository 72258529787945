import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import SidebarContainer from "../sidebars/SidebarContainer";
import { CONFIGS } from "../../constants/constants.js";
import { Pagination } from "semantic-ui-react";
import { atCrs, brandDataCourse } from "../../actions/gradebookAction.js";
import {
  setSelectedCourse,
  setAttendance,
  authenticateCourse,
  getParam,
} from "../../actions/courseAction.js";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";
import {
  placementProfileData,
  placementJobData,
  changeSaveStatus,
  addLearnerRemarks,
  getProfileInfoOnly,
} from "../../actions/profileAction.js";
import CourseHeader from "../headers/CourseHeader.jsx";
import "./attendance.scss";
import Axios from "axios";
import { ROUTES } from "../../constants/routes.js";
import Loader from "../utils/Loader.js";
import "./assets/styles.scss";
import { zoomSignature } from "../../actions/batchAction.js";
import { resetLoadingData } from "../../actions/utilAction.js";
const cookies = new Cookies();

const localizer = momentLocalizer(moment);

const CalendarView = (props) => {
  let pid, gid, cid;
  const [ifLoading, setIfLoading] = useState(false);

  const [brand, setBrand] = useState();
  const [selfPaced, setSelfPaced] = useState();
  const [joining, setJoining] = useState();
  const [uid, setUid] = useState();
  const [courseName, setCourseName] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [impartusEnabled, setImpartusEnabled] = useState();
  const [batchName, setBatchName] = useState();
  const [courseLocation, setCourseLocation] = useState();
  const [dp, setDp] = useState();
  const [userName, setUserName] = useState();
  const [primaryEmail, setPrimaryEmail] = useState();

  const [oppShared, setOppShared] = useState(0);
  const [showWeightage, setShowWeightage] = useState(false);
  const [showPassMarks, setShowPassMarks] = useState(false);
  const [showPlcStatus, setShowPlcStatus] = useState(false);
  const [placementEnable, setPlacementEnable] = useState(false);
  const [gradebookType, setGradebookType] = useState(1);
  const [breadCrumbsTemp, setBreadCrumbsTemp] = useState([]);
  const [showPlcPassStatus, setShowPlcPassStatus] = useState(
    "Congratulations! You are eligible for Placement Criteria"
  );
  const [showPlcFailStatus, setShowPlcFailStatus] = useState(
    "You are not eligible for the Placement.  "
  );
  const [plcPassStatusText, setPlcPassStatusText] = useState("PASS");
  const [plcFailStatusText, setPlcFailStatusText] = useState("FAIL");
  const [showContentStatus, setShowContentStatus] = useState(false);

  // const [metrics, setMetrics] = useState({});
  // const [data, setData] = useState({});
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  // const [loading, setIfLoading] = useState(false);
  // const [filters, setFilters] = useState({
  //   type: "",
  // });
  // const [searchVal, setSearchVal] = useState("");
  // const [debounceVal, setDebounceVal] = useState("");
  // const debounceValue = useDebounce(searchVal, 1500);
  // let ROWS_PER_PAGE = 10;

  useEffect(() => {
    console.log("This is my props: ", props);
    let query = window.location.href;
    query = query.split("/");
    pid = query[5];
    gid = query[6];
    cid = query[7];
    console.log({ query, cid });
    (async () => {
      // window.FreshworksWidget("hide", "launcher");
      //   setIfLoading(true);
      await authenticatCourse();
      resetLoadingData();
      await props.getProfileInfoOnly();
      await fetchBrandDataCrs();
      setDp(props.ppic);
      setUserName(props.profileName);
      setPrimaryEmail(props.primaryEmail);
      await fetchCalendarData();
    })();
  }, []);

  const authenticatCourse = async () => {
    console.log("In authentic Course!");
    if (!cookies.get("isLoggedIn")) {
      return window.open(CONFIGS.WEB_DASHBOARD_BASE_URL, "_self");
    }
    console.log({ pid, gid, cid });
    atCrs({
      pid,
      gid,
      cid,
      dispatchIndividualBrand: props.dispatchIndividualBrand,
    })
      .then(function (res) {
        console.log("At Crs ::: ", { res });
        var expirydate = new Date();
        expirydate.setDate(expirydate.getDate() + 180);
        cookies.set("crs_tkn", res.data.data.at, {
          expires: expirydate,
          domain: CONFIGS.DOMAIN,
          path: "/",
        });

        if (res.data.data.joined) {
          setJoining(new Date(res.data.data.joined));
        }
        if (res.data.data.uid) {
          setUid(res.data.data.uid);
        }
        if (res.data.data.course.name) {
          setCourseName(res.data.data.name);
          let cname = res.data.data.name;
          setBreadCrumbsTemp([
            { cname, link: "/view/?cid=" + getParam("cid") },
          ]);
          console.log({ cname: res.data.data.name });
          console.log({ breadCrumbsTemp });
        }
        if (res.data.data && res.data.data.phoneno) {
          setPhoneNo(res.data.data.phoneno);
        }
        if (res.data.data.selfPaced) {
          setSelfPaced(res.data.data.selfPaced);
        }
        setImpartusEnabled(res.data.data.course.impartus_enabled);
        setShowPassMarks(res.data.data.course.show_pass_marks);
        setShowWeightage(res.data.data.course.show_weightage);
        if ("show_content_status" in res.data.data.course) {
          setShowContentStatus(res.data.data.course["show_content_status"]);
        }

        let passStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_pass_text
          : "Congratulations! You are eligible for Placement Criteria";
        let failStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_fail_text
          : "You are not eligible for the Placement.  ";
        let plcpassStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_pass_status_text
          : "PASS";
        let plcfailStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_fail_status_text
          : "FAIL";

        setPlcPassStatusText(plcpassStatus);
        setPlcFailStatusText(plcfailStatus);

        setShowPlcFailStatus(failStatus);
        setShowPlcPassStatus(passStatus);
        let grdtype = res.data.data.course.gradebook_type
          ? res.data.data.course.gradebook_type
          : 1;
        setGradebookType(grdtype);
        setPlacementEnable(res.data.data.course.plcmnt_enable);

        let showplc = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.gradebook_show_plcmnt_stts
          : false;
        setShowPlcStatus(showplc);
        setBatchName(res.data.data.course.btch_name);
        setCourseLocation(res.data.data.location);

        let courseFound = res.data.data.course;
        if (res.data.data.location) {
          courseFound.loc_name = res.data.data.location;
        }
        if (courseFound) {
          props.setSelectedCourse(courseFound);
        }
        props.setAttendance(res.data.data.showAttendance);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const fetchBrandDataCrs = async () => {
    brandDataCourse(cid)
      .then((response) => {
        var brand = response.data.data.brand;
        if (brand) {
          setBrand(brand);
          props.dispatchIndividualBrand(brand);
          if (
            !cookies.get("loaderColor") ||
            cookies.get("loaderColor") != brand.loaderColorIndex
          ) {
            if (cookies.get("loaderColor") != brand.loaderColorIndex) {
              cookies.remove("loaderColor", {
                path: "/",
                domain: CONFIGS.DOMAIN,
              });
            }
            var expirydate = new Date();
            expirydate.setDate(expirydate.getDate() + 180);
            cookies.set("loaderColor", brand.loaderColorIndex, {
              expires: expirydate,
              domain: CONFIGS.DOMAIN,
              path: "/",
            });
          }
        } else {
          cookies.remove("loaderColor", {
            path: "/",
            domain: CONFIGS.DOMAIN,
          });
        }
      })
      .catch((err) => {
        props.dispatchIndividualBrand();
        console.log({ err });
      });
  };

  const [eventList, setEventList] = useState([]);

  console.log({ props });
  let { typeof: typeOf, id } = props.match.params;

  const getCalendarData = async function ({ typeOf, id }) {
    setIfLoading(true);
    console.log("This is getAttendanceMetricsForLearner");
    let configs = {};
    let headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    console.log(
      "\n\n\n\n\n props.selected_course ",
      props.selected_course,
      " \n\n\n\n"
    );
    try {
      let response = await Axios.post(
        ROUTES.GET_CALENDAR_DATA,
        { pid, cid: cid },
        configs
      );
      if (response.data.success) {
        console.log("Successfull!");
      } else {
        console.log("UnSuccessful!");
      }
      for (let i = 0; i < response.data.data.length; i++) {
        response.data.data[i].start = new Date(response.data.data[i].start);
        response.data.data[i].end = new Date(response.data.data[i].end);
      }
      console.log({ eventsList: response.data.data });
      setEventList(response.data.data);
      setIfLoading(false);
    } catch (err) {
      console.log(err);
      setIfLoading(false);
    }
  };

  // useEffect(() => {
  //   console.log("This is the useEffect!", { typeOf, id });
  // setIfLoading(true)
  const fetchCalendarData = async () => {
    getCalendarData({ typeOf, id })
      .then((res) => {
        console.log(res);
        setIfLoading(false);
        if (res) {
          for (let i = 0; i < res.length; i++) {
            res[i].start = new Date(res[i].start);
            res[i].end = new Date(res[i].end);
          }
          console.log({ res });
          setEventList(res);
        }
      })
      .catch((err) => {
        console.log("error in getData :: ", err);
        setIfLoading(false);
      });
  };
  // }, []);
  console.log({ eventList });

  const handleSelectedEvent = (event) => {
    console.log("In selected event :: ", event);
    if (event.type !== 3) {
      joinZoomMeeting(event._id);
    }

    // window.open(event.url, "_blank");
  };
  const joinZoomMeeting = async function (liv_lec_id) {
    console.log("inside joinZoomMeeting");
    console.log(liv_lec_id, " >>>> liv_lec_id");

    if (!navigator.onLine) {
      return alert("You are offline. Please check your internet connection.");
    }
    setIfLoading(true);
    zoomSignature(liv_lec_id, pid)
      .then(function (res) {
        console.log(res, "signature response");
        const authArr = res.data.data.auth.split(",");
        const meeting_number = atob(authArr[0]);
        const meeting_password = atob(authArr[1]);

        var meetingConfig = {};
        meetingConfig["signature"] = res.data.data.signature;
        meetingConfig["meetingNumber"] = meeting_number;
        meetingConfig["password"] = meeting_password;
        meetingConfig["apiKey"] = res.data.data.api_key;
        meetingConfig["userName"] = res.data.data.user_name;
        meetingConfig["userEmail"] = res.data.data.user_email;
        meetingConfig["leaveUrl"] = window.location.href;
        meetingConfig["isSupportAV"] = true;
        var joinUrl =
          CONFIGS.WEB_DASHBOARD_ENDPOINT +
          "zoom_meeting?" +
          serialize(meetingConfig);
        console.log(joinUrl);
        setIfLoading(false);
        window.open(joinUrl);
      })
      .catch(function (err) {
        console.log(err);
        if (err.status && err.status == 498 && err.data.code == 4100) {
          authenticateCourse(function () {
            joinZoomMeeting(liv_lec_id);
          });
        } else {
          if (err.response.data && err.response.data.code == 204035) {
            alert(err.response.data.message);
          } else {
            alert("Something went wrong. Please try again!");
          }
        }
        setIfLoading(false);
      });
  };

  const serialize = function (obj) {
    // eslint-disable-next-line no-shadow
    var keyOrderArr = [
      "name",
      "mn",
      "email",
      "pwd",
      "role",
      "lang",
      "signature",
      "china",
    ];

    Array.intersect = function () {
      var result = new Array();
      var obj = {};
      for (var i = 0; i < arguments.length; i++) {
        for (var j = 0; j < arguments[i].length; j++) {
          var str = arguments[i][j];
          if (!obj[str]) {
            obj[str] = 1;
          } else {
            obj[str]++;
            if (obj[str] == arguments.length) {
              result.push(str);
            }
          }
        }
      }
      return result;
    };

    if (!Array.prototype.includes) {
      Object.defineProperty(Array.prototype, "includes", {
        enumerable: false,
        value: function (obj) {
          var newArr = this.filter(function (el) {
            return el === obj;
          });
          return newArr.length > 0;
        },
      });
    }

    var tmpInterArr = Array.intersect(keyOrderArr, Object.keys(obj));
    var sortedObj = [];
    keyOrderArr.forEach(function (key) {
      if (tmpInterArr.includes(key)) {
        sortedObj.push([key, obj[key]]);
      }
    });
    Object.keys(obj)
      .sort()
      .forEach(function (key) {
        if (!tmpInterArr.includes(key)) {
          sortedObj.push([key, obj[key]]);
        }
      });
    var tmpSortResult = (function (sortedObj) {
      var str = [];
      for (var p in sortedObj) {
        if (typeof sortedObj[p][1] !== "undefined") {
          str.push(
            encodeURIComponent(sortedObj[p][0]) +
              "=" +
              encodeURIComponent(sortedObj[p][1])
          );
        }
      }
      return str.join("&");
    })(sortedObj);
    return tmpSortResult;
  };

  if (ifLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className="course-page gradebookPage">
        <CourseHeader
          breadCrumbs={[{ name: courseName, link: `/view?cid=${cid}` }]}
          individualBrand={""}
        />
        <SidebarContainer crs_pg_id={props.selected_course.crs_pg_id} />
        <div
          className="col-xs-12 rvm-v1"
          id="content"
          style={{ marginTop: "55px" }}
        >
          <div className="breadCrumbsContainer">
            <div className="rvm-v1-header-brdsc-ctnr breadCrumbs-row">
              <div className="breadCrumbs">
                <a href="/dashboard">
                  <span>My Courses</span>
                </a>
                <i
                  style={{ fontSize: "small", marginLeft: "10px" }}
                  className="fa fa-chevron-right"
                ></i>
                <a href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}view/?cid=${cid}`}>
                  {courseName}
                </a>
                <i
                  style={{
                    fontSize: "small",
                    marginLeft: "10px",
                  }}
                  className="fa fa-chevron-right"
                ></i>
                <a href={""} className="rvm-v1-header-brdsc-active">
                  <span>Calendar</span>
                </a>
              </div>
            </div>
          </div>
          <div className="myMainDiv container" style={{ marginTop: "10px" }}>
            <Calendar
              localizer={localizer}
              events={eventList}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 700 }}
              onSelectEvent={(e) => handleSelectedEvent(e)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profileName: state.profile.p_name,
  email: state.primaryEmail.secondary_emails[0],
  primaryEmail: state.profile.email,
  ppic: state.profile.ppic,
});

const mapDispatchToProps = {
  dispatchIndividualBrand,
  getProfileInfoOnly,
  setSelectedCourse,
  setAttendance,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CalendarView)
);
